<template>
    <v-dialog :persistent="editingBundle" v-model="editBundleDialog" max-width="700">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip right :disabled="!showTooltip">
                <template v-slot:activator="{ on: tooltip }">
                    <div v-on="{ ...tooltip, ...dialog }">
                        <slot name="dialogactivator">
                            <v-btn icon text color="secondary"><v-icon>edit</v-icon></v-btn>
                        </slot>
                    </div>
                </template>
                <span>Edit</span>
            </v-tooltip>
        </template>
        <v-card flat>
            <v-card-title>
                <div class="d-flex align-center w-100">
                    <v-icon>edit</v-icon>
                    <span class="text-uppercase secondary--text ml-1">Edit {{ bundleData.bundle_type_name }}</span>
                    <v-spacer></v-spacer>
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-alert type="info" v-if="highlightHandBack" class="mt-6">
                    Enable handback visibility below for students to see their corrections and grades
                </v-alert>
                <v-row align="center">
                    <v-col cols="12" md="5"><span class="subtitle-2">Handback Visibility</span></v-col>
                    <v-col>
                        <v-radio-group v-model="showBundleHandbacks" row>
                            <v-radio label="Hidden" :value="false"></v-radio>
                            <v-radio label="Visible" :value="true"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row align="center" v-if="!highlightHandBack">
                    <v-col cols="12" md="5"><span class="subtitle-2">Assignment description</span></v-col>
                    <v-col>
                        <v-textarea rows="2" v-model="bundleDescription" outlined></v-textarea>
                    </v-col>
                </v-row>
                <v-row align="center" v-if="!highlightHandBack">
                    <v-col cols="12" md="5"><span class="subtitle-2">Hand-in deadline</span></v-col>
                    <v-col>
                        <div class="d-flex flex-column">
                            <v-menu
                                v-model="bundleDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="bundleDeadlineDate" readonly label="Date" v-bind="attrs" v-on="on" outlined></v-text-field>
                                </template>
                                <v-date-picker v-model="bundleDeadlineDate" @input="bundleDateMenu = false"></v-date-picker>
                            </v-menu>
                            <v-menu
                                v-model="bundleTimeMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="310px"
                                min-width="310px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="bundleDeadlineTime" label="Time" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                </template>
                                <time-picker v-if="bundleTimeMenu" v-model="bundleDeadlineTime" use-seconds format="24hr"></time-picker>
                            </v-menu>
                            <div v-if="bundleHandinDatetime" class="d-flex">
                                <span class="mr-1">Displayed deadline:</span>
                                <span class="font-weight-medium">{{ bundleHandinDatetime | dateTimeToHuman }}</span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <v-row dense>
                    <v-col cols="12">
                        <div>Add a message to notify students of changes (e.g. due date updated, grades now visible) or select no notifications below</div>
                        <v-text-field
                            :disabled="skipNotify"
                            v-model="updateMessage"
                            height="100"
                            placeholder="Please detail your changes here"
                            outlined></v-text-field>
                    </v-col>
                    <v-col>
                        <v-checkbox v-model="skipNotify" label="Do not notify students"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <div class="d-flex align-center justify-end w-100">
                    <v-btn :disabled="editingBundle" color="secondary" text @click="editBundleDialog = false">close</v-btn>
                    <v-btn :loading="editingBundle" :disabled="disableEditButton" @click="editBundle" text color="primary">
                        <span v-if="!skipNotify">Save & Notify</span>
                        <span v-else>Save</span>
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { makeShortId } from '@/utils'
import TimePicker from '@/components/TimePicker.vue'

export default {
    name: 'BundleEditDialog',
    components: {
        TimePicker
    },
    props: {
        bundleData: Object,
        highlightHandBack: {
            type: Boolean,
            default: false
        },
        showTooltip: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            editBundleDialog: false,
            editingBundle: false,
            bundleName: '',
            bundleDescription: '',
            bundleDeadlineTime: null,
            bundleTimeMenu: false,
            bundleDeadlineDate: null,
            bundleDateMenu: false,
            showBundleHandbacks: false,
            updateMessage: '',
            skipNotify: false
        }
    },
    computed: {
        bundleHandinDatetime() {
            if (this.bundleDeadlineDate && this.bundleDeadlineTime) {
                const datetime = `${this.bundleDeadlineDate} ${this.bundleDeadlineTime}`
                const UTCDatetime = new Date(datetime).toISOString()
                return UTCDatetime
            }
            return ''
        },
        disableEditButton() {
            if (!this.bundleName || !this.bundleDeadlineDate || !this.bundleDeadlineTime || (!this.updateMessage && !this.skipNotify)) {
                return true
            }
            return false
        }
    },
    mounted() {
        this.bundleName = this.bundleData.long_id
        this.bundleDescription = this.bundleData.description
        this.showBundleHandbacks = this.bundleData.handbacks_visible ? this.bundleData.handbacks_visible : false
        this.setPickerDateTime()
    },
    methods: {
        setPickerDateTime() {
            const localDateFromUTC = new Date(this.bundleData.allow_handins_until)
            const mm = localDateFromUTC.getMonth() + 1 // getMonth() is zero-based
            const dd = localDateFromUTC.getDate()
            const hh = localDateFromUTC.getHours()
            const min = localDateFromUTC.getMinutes()
            const ss = localDateFromUTC.getSeconds()
            const pickerDate = [localDateFromUTC.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-')
            const pickerTime = [(hh > 9 ? '' : '0') + hh, (min > 9 ? '' : '0') + min, (ss > 9 ? '' : '0') + ss].join(':')
            this.bundleDeadlineDate = pickerDate
            this.bundleDeadlineTime = pickerTime
        },
        editBundle() {
            this.editingBundle = true
            const patchBody = {}
            if (this.bundleName !== this.bundleData.long_id) {
                patchBody.long_id = this.bundleName
                patchBody.short_id = makeShortId(this.bundleName)
            }
            if (this.bundleDescription !== this.bundleData.description) {
                patchBody.description = this.bundleDescription
            }
            if (this.bundleHandinDatetime && this.bundleHandinDatetime !== this.bundleData.allow_handins_until) {
                patchBody.allow_handins_until = this.bundleHandinDatetime
            }
            if (this.showBundleHandbacks !== this.bundleData.handbacks_visible) {
                patchBody.handbacks_visible = this.showBundleHandbacks
            }
            if (this.updateMessage) {
                patchBody.message = this.updateMessage
            }
            this.$axios
                .patch(`/bundles/${this.bundleData.bid}`, patchBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Successfully edited ${this.bundleData.long_id}` + (this.updateMessage ? ' and students have been notified.' : ''),
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('spaceStore/fetchSpaceBundles', this.$route.params.sid)
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to edit ${this.bundleData.long_id}`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.editingBundle = false
                    this.editBundleDialog = false
                })
        }
    }
}
</script>
